import Image from 'next/legacy/image'
import { imageLoader } from '@/utils/ImageLoaders'
import { ButtonLink } from '@/components/ui/ButtonLinks'
import { RichTextContent } from '@/components/design/RichTextContent'

import { ComponentTwoColumnInfographic } from 'types/generated/contentful-types'

type TwoColumnInfographicBulletPointProps = {
  icon: string
  heading: string
  copy: string
  last?: boolean
}

type ComponentTwoColumnInfographicProps = Omit<
  ComponentTwoColumnInfographic,
  'contentfulMetadata' | 'sys'
>

export function TwoColumnInfographicBulletPoint({
  icon,
  heading,
  copy,
  last,
}: TwoColumnInfographicBulletPointProps): JSX.Element {
  return (
    <li className="flex gap-4 mb-10">
      <div
        className="relative flex flex-col"
        data-name="two-col-infographic-bullet"
      >
        <div className="block w-8 lg:w-13">
          <Image
            loader={({ src }) =>
              imageLoader({
                src: src,
                width: 48,
                quality: 90,
              })
            }
            src={icon}
            alt="Icon"
            layout="responsive"
            width={48}
            height={48}
            sizes="20vw"
          />
        </div>
        <div
          className={`relative flex-1 ${last ? '' : 'point-row'}`}
          aria-hidden
          role="presentation"
        />
      </div>
      <div data-name="two-col-infographic-content">
        <h3 className="fora-text-h3 md:fora-text-h5">{heading}</h3>
        <div className="mt-2 global-richtext">
          <p>{copy}</p>
        </div>
      </div>
    </li>
  )
}

export default function TwoColumnInfographic({
  title,
  teaserCopy,
  headingOne,
  copyOne,
  headingTwo,
  copyTwo,
  headingThree,
  copyThree,
  ctaText,
  ctaUrl,
  sectionId,
  urlTarget,
}: ComponentTwoColumnInfographicProps): JSX.Element {
  return (
    <section
      id={sectionId ?? undefined}
      className="flex flex-col items-center justify-between max-w-[1920px] m-auto gap-10 p-4 py-12 lg:p-12 lg:flex-row bg-darkSand"
    >
      <div>
        <h2 className="fora-text-h3" data-name="title">
          {title}
        </h2>
        {teaserCopy && (
          <div
            className="m-auto mt-4 lg:mb-0 mb-8 max-w-[600px]"
            data-name="teaser-copy"
          >
            <RichTextContent richText={teaserCopy} />
          </div>
        )}
        {ctaUrl && ctaText && (
          <div className="mt-6 lg:mt-14">
            <ButtonLink
              href={ctaUrl ?? ''}
              target={(urlTarget as '_self' | '_blank') || '_self'}
              text={ctaText ?? ''}
              theme="primary"
              customClass="px-[100px]"
            />
          </div>
        )}
      </div>
      <ul className="max-w-[550px]">
        <TwoColumnInfographicBulletPoint
          icon="https://media.foratravel.com/image/upload/v1718799466/circle-num-1_gd0sfj.svg"
          heading={headingOne || ''}
          copy={copyOne || ''}
        />
        <TwoColumnInfographicBulletPoint
          icon="https://media.foratravel.com/image/upload/v1718799466/circle-num-2_mjlw5s.svg"
          heading={headingTwo || ''}
          copy={copyTwo || ''}
        />
        <TwoColumnInfographicBulletPoint
          icon="https://media.foratravel.com/image/upload/v1718799466/circle-num-3_iuxumk.svg"
          heading={headingThree || ''}
          copy={copyThree || ''}
          last
        />
      </ul>
    </section>
  )
}
